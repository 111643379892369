import { useCallback, useMemo, useEffect } from "react";
import {
  useBroadbandOfferingsByPlans,
  useBroadbandProviders,
  usePlans,
} from "@contact/data-access-hooks";
import { isEmpty, omit, sortBy } from "lodash-es";
import {
  BroadbandOfferingsApiResponse,
  Offering,
  Plan,
  UserType,
} from "@contact/data-access";
import { useAgentConfig } from "Utils/Config";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";
import {
  useIsGasInServiceList,
  usePropertyInfoAddressIsSouthIsland,
  useServicesSelector,
  useSiteCoreAllPlanOffers,
  useBroadbandInfoAddressDetailsSelector,
  usePlanInfoSelectedPlanSelector,
  usePlanInfoSelectedPlanSetter,
  useSiteCoreBBOffers,
  useBroadbandRatesSetter,
  usePlanInfoSelectedBBPlan,
  useCustomerInfoServiceAddressSelector,
  useLowestBroadbandPrice,
} from ".";
import { BB_BUNDLE_PLAN_ID } from "Config/Constants";
import { useEnrichedCards } from "../shared/BillCompare";
import { useBillingEligibilitySmartTimeOfUse } from "./BillingEligibility";

export const usePlansData = (): { data: Plan[]; isLoading: boolean } => {
  const { data, isSuccess, isLoading } = usePlans("v1", baseAxiosConfig);
  return useMemo(
    () => ({
      data: isSuccess && data ? data.Plans : [],
      isLoading,
    }),
    [data, isLoading, isSuccess]
  );
};
export interface PlanOffer {
  fields: {
    ExistingPlan: {
      fields: {
        Title: {
          value: string;
        };
        ApiPlanId: {
          value: string;
        };
      };
    };
    DiscountsOverride: {
      value: string;
    };
    AASFlogoImage: {
      value: {
        src: string;
      };
    };
  };
}

export const usePlanOffers = (
  isCSR: boolean,
  journeyType: string,
  basicPlan: string
): { data: PlanOffer[]; isLoading: boolean } => {
  const { data: plansData, isLoading: isPlansDataLoading } = usePlansData();
  const {
    data: allPlanOffers,
    isLoading: isAllPlanOffersLoading,
  } = useSiteCoreAllPlanOffers(isCSR, journeyType);
  const isGasInServiceList = useIsGasInServiceList();
  const selectedServiceList = useServicesSelector();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  const isTimeOfUseEligible = useBillingEligibilitySmartTimeOfUse();
  const {
    data: lowestBroadbandPrice,
    isLoading: isLowestBroadbandPriceLoading,
  } = useLowestBroadbandPrice(isCSR, journeyType);

  return useMemo(() => {
    let data: any = allPlanOffers;
    const isLoading =
      isPlansDataLoading ||
      isAllPlanOffersLoading ||
      isLowestBroadbandPriceLoading;

    const gasOnly = isGasInServiceList && selectedServiceList.length === 1;
    if (gasOnly) {
      if (!isSouthIsland) {
        if (allPlanOffers.length) {
          const filteredPlans = data.filter(
            (plan) =>
              plan.fields.ExistingPlan.fields.ApiPlanId.value === basicPlan
          );
          const selectedPlan = plansData.find(
            (plan) =>
              plan.Id ===
              filteredPlans[0].fields.ExistingPlan.fields.ApiPlanId.value
          );
          if (selectedPlan) {
            data = filteredPlans;
          }
        }
      }
    }

    if (isTimeOfUseEligible === false) {
      const timeOfUsePlans = plansData
        .filter((plan) => plan.TOUPlan)
        .map((plan) => plan.Id);
      data = data.filter(
        (plan) =>
          !timeOfUsePlans.includes(
            plan.fields.ExistingPlan.fields.ApiPlanId.value
          )
      );
    }

    // Replace lowest broadband price in broadband bundle plan
    const broadbandBundlePlan = data.find(
      (item) =>
        item.fields.ExistingPlan.fields.ApiPlanId.value === BB_BUNDLE_PLAN_ID
    );
    if (broadbandBundlePlan && lowestBroadbandPrice) {
      const div = document.createElement("div");
      div.innerHTML = broadbandBundlePlan.fields.PlanPromo.value;
      const priceElement = div.querySelector("#lowest_bb_price");
      if (priceElement?.textContent) {
        priceElement.textContent = priceElement.textContent.replace(
          /\$\d+/,
          "$" + lowestBroadbandPrice
        );
      }
      data = data.map((item) =>
        item.fields.ExistingPlan.fields.ApiPlanId.value === BB_BUNDLE_PLAN_ID
          ? {
              ...broadbandBundlePlan,
              fields: {
                ...broadbandBundlePlan.fields,
                PlanPromo: {
                  ...broadbandBundlePlan.fields.PlanPromo,
                  value: div.innerHTML,
                },
              },
            }
          : item
      );
    }

    return {
      data,
      isLoading,
    };
  }, [
    allPlanOffers,
    isPlansDataLoading,
    isAllPlanOffersLoading,
    isLowestBroadbandPriceLoading,
    isGasInServiceList,
    selectedServiceList.length,
    isTimeOfUseEligible,
    isSouthIsland,
    plansData,
    basicPlan,
    lowestBroadbandPrice,
  ]);
};

export const useAvailablePlanOffers = (
  isCSR: boolean,
  journeyType: string,
  basicPlan: string
) => {
  const Constants = useAgentConfig();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  const { data: planOffers } = usePlanOffers(isCSR, journeyType, basicPlan);
  const enrichedPlans = useEnrichedCards();

  const availablePlanOffers = useMemo(
    () =>
      isSouthIsland
        ? (planOffers || []).filter(
            (plan) =>
              plan.fields.ExistingPlan.fields.ApiPlanId.value !==
              Constants.planServiceDetails.simplicityPlan
          )
        : planOffers,
    [Constants.planServiceDetails.simplicityPlan, isSouthIsland, planOffers]
  );

  return isEmpty(enrichedPlans)
    ? availablePlanOffers
    : sortBy(availablePlanOffers, [
        (planOffer: PlanOffer) =>
          -(
            enrichedPlans.find(
              (enrichedPlan) =>
                enrichedPlan.planId ===
                planOffer.fields.ExistingPlan.fields.ApiPlanId.value
            )?.amt || 0
          ),
      ]);
};

export const useBroadbandOffersByPlanId = (
  isCSR: boolean,
  journeyType: string,
  planId?: string
): { data?: BroadbandOfferingsApiResponse; isLoading: boolean } => {
  const {
    data: allPlanOffers,
    isLoading: isAllPlanOffersLoading,
  } = useSiteCoreAllPlanOffers(isCSR, journeyType);
  const selectedServices = useServicesSelector();
  const serviceAddress = useCustomerInfoServiceAddressSelector();
  const addressDetails = useBroadbandInfoAddressDetailsSelector();
  const constants = useAgentConfig();
  const userType = constants.journeyChannel as UserType;

  const {
    data,
    isLoading: isBroadbandOfferingsByPlansLoading,
  } = useBroadbandOfferingsByPlans(
    "v2",
    allPlanOffers
      .map((item) => item?.fields?.ExistingPlan?.fields?.ApiPlanId?.value)
      .filter((item) => !!item),
    selectedServices.includes("BB")
      ? selectedServices
      : [...selectedServices, "BB"],
    serviceAddress ? addressDetails : undefined,
    userType,
    undefined,
    baseAxiosConfig
  );

  return useMemo(
    () => ({
      data: data && {
        ...omit(data, ["plans", "lookupComplete", "resultCode", "address"]),
        Offerings:
          data.plans?.find((item) => item?.planId === planId)?.offerings || [],
        LookupComplete: data.lookupComplete,
        ResultCode: data.resultCode,
        Address: data.address,
        Modems: data.modems,
      },
      isLoading: isAllPlanOffersLoading || isBroadbandOfferingsByPlansLoading,
    }),
    [data, isAllPlanOffersLoading, isBroadbandOfferingsByPlansLoading, planId]
  );
};

export const useBroadbandOffers = (
  isCSR: boolean,
  journeyType: string,
  planId?: string
): {
  data: {
    Offerings: Offering[];
    fibreAvailabilityStatus?: string;
    ontStatus?: string;
    defaultResponse?: boolean;
  };
  isLoading: boolean;
} => {
  const selectedPlan = usePlanInfoSelectedPlanSelector();
  const [selectedBBPlan, setSelectedBBPlan] = usePlanInfoSelectedBBPlan();
  const setBroadbandRates = useBroadbandRatesSetter();

  const {
    data: broadBandOfferings,
    isLoading: isBroadBandOfferingsLoading,
  } = useBroadbandOffersByPlanId(
    isCSR,
    journeyType,
    planId || selectedPlan?.Id
  );

  const {
    data: siteCoreBBOffers,
    isLoading: isSiteCoreBBOffersLoading,
  } = useSiteCoreBBOffers(isCSR, journeyType);

  useEffect(() => {
    if (selectedBBPlan?.Id && broadBandOfferings?.Offerings) {
      const selectedBBOffer = broadBandOfferings.Offerings.find(
        (offering) => offering.Id === selectedBBPlan.Id
      );

      if (selectedBBOffer) {
        setSelectedBBPlan(selectedBBOffer);
        setBroadbandRates(selectedBBOffer);
      } else {
        setSelectedBBPlan(undefined);
        setBroadbandRates(undefined);
      }
    }
  }, [
    broadBandOfferings,
    selectedBBPlan,
    setBroadbandRates,
    setSelectedBBPlan,
  ]);

  return {
    data: broadBandOfferings || {
      Offerings: siteCoreBBOffers || [],
    },
    isLoading: isBroadBandOfferingsLoading || isSiteCoreBBOffersLoading,
  };
};

export const useBBProviders = () => {
  return useBroadbandProviders("v2", baseAxiosConfig);
};

export function useSelectedPlanNameSetter() {
  const setSelectedPlan = usePlanInfoSelectedPlanSetter();
  const { data: plans } = usePlansData();

  return useCallback(
    (name: string) => {
      const plan = plans.find((plan) => plan.Name === name || plan.Id === name);
      if (plan) {
        setSelectedPlan(plan);
      }
    },
    [setSelectedPlan, plans]
  );
}

export const useIsFibreAvailable = (isCSR: boolean, journeyType: string) => {
  const { data } = useBroadbandOffers(isCSR, journeyType);
  const fibreAvailabilityStatus = data?.fibreAvailabilityStatus;
  return (
    fibreAvailabilityStatus === undefined || fibreAvailabilityStatus === "Yes"
  );
};

export const useIsONTConnected = (isCSR: boolean, journeyType: string) => {
  const { data } = useBroadbandOffers(isCSR, journeyType);
  return data?.ontStatus === "Connected";
};
