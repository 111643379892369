import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { ConfigPath, ConfigVersion } from '..';

export const getConfig = (
  version: ConfigVersion,
  application: string,
  profile: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<any>(
    ConfigPath[version](application, profile)
  );
};
