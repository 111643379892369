import { useMemo, useCallback } from "react";
import { isEmpty } from "lodash-es";
import {
  JourneyState,
  JourneyType,
  ServiceUserType,
} from "@contact/ui-bill-compare";
import { initialState } from "../../Reducers/CSRAgent/BillCompareRedux";
import { baseAxiosConfig } from "Redux/Actions/ApiCalls";
import { useAgentConfig } from "Utils/Config";
import {
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
  SERVICE_ID_ELECTRICITY,
} from "../../../Config/Constants";
import { useDispatch, useSelector } from "react-redux";

import { BillCompareState } from "@contact/ui-bill-compare";
import { updateBillCompareOutput } from "Redux/Actions/CSRAgent/BillCompareActions";

import { USER_TYPE_LOW } from "react-components/Components/Rates/constants";
import {
  useServicesSelector,
  usePropertyInfoAddressIsSouthIsland,
  useIsFibreAvailable,
  usePropertyInfoAddress,
  usePropertyInfoElectricityICPSelector,
  useGasICPDetails,
  useElectricityICPDetails,
  usePropertyInfoGasICPSelector,
  useIsElectricityInServiceList,
  usePlanOffers,
  usePlansData,
  useElectricityRates,
  usePlanInfoSelectedPlanSelector,
  useBroadbandInfoAddressDetailsSelector,
  useSiteCoreBCPromoCode,
  useServices,
  useIsGasInServiceList,
  usePropertyInfoElectricityUserType,
  useGasRates,
  useIsLPGInServiceList,
  useStandardElectricityPrices,
  usePropertyInfoAddressDetailsSelector,
  useIsLowGasPricesAvailable,
  useBroadbandOffers,
  useIsLPGOnly,
  useIsNGas,
  useIsRLPGOnly,
  useSitecoreDisclaimer,
} from "../CSRJoinJourney";
import { useBillingEligibilitySmartTimeOfUse } from "../CSRJoinJourney/BillingEligibility";

import { useCSRJourneysConfig } from "@contact/data-access-hooks";

interface planItems {
  amt: number;
  planId: string;
}

export const useIsBillCompareEnabled = (
  isCSR: boolean,
  journeyType: string
) => {
  const isBillCompareAvailable = useIsBillCompareAvailable(isCSR, journeyType);
  const { data: configData } = useCSRJourneysConfig("v1");
  const isBillCompareFeatureOn = configData?.CustomerJourneysBillCompareFlag;

  return isBillCompareAvailable && isBillCompareFeatureOn;
};

const useIsBillCompareAvailable = (isCSR: boolean, journeyType: string) => {
  const [serviceAddress] = usePropertyInfoAddress();
  const icp = usePropertyInfoElectricityICPSelector();
  const gasIcp = usePropertyInfoGasICPSelector();

  const isElectricityInServiceList = useIsElectricityInServiceList();
  const isLPGOnly = useIsLPGOnly();
  const isNGas = useIsNGas();
  const isRLPGOnly = useIsRLPGOnly();
  const { data: gasRate, isLoading: isGasRateLoading } = useGasRates(gasIcp);
  const selectedPlan = usePlanInfoSelectedPlanSelector();
  const planId = selectedPlan?.Id;
  const hasNoIcp = !icp;
  const { planServiceDetails } = useAgentConfig();

  const ratesConstants = planServiceDetails.joinRates;
  const {
    data: electricityRates,
    isLoading: isElectricityRatesLoading,
  } = useElectricityRates(icp, planId, hasNoIcp, ratesConstants.rateLabels);
  const [selectedServiceList] = useServices();

  const [elecUserType] = usePropertyInfoElectricityUserType();
  const elecUsageType =
    elecUserType === USER_TYPE_LOW ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD;

  const {
    isLoading: isElectricityRegistryDetailsLoading,
  } = useElectricityICPDetails(icp);
  const { isLoading: isGasRegistryDetailsLoading } = useGasICPDetails(gasIcp);

  const hasElectricityRatesError = useCallback(
    (rates) => {
      return Boolean(
        rates.find(
          (rate) =>
            rate.type === SERVICE_ID_ELECTRICITY &&
            ((elecUsageType === USAGE_TYPE_STANDARD &&
              !rate.standardRatesGSTExclusive.length) ||
              (elecUsageType === USAGE_TYPE_LOW &&
                !rate.lowRatesGSTExclusive.length))
        )
      );
    },
    [elecUsageType]
  );

  const electricityRateshasError =
    isElectricityInServiceList &&
    (electricityRates?.length === 0 ||
      hasElectricityRatesError(electricityRates));

  const gasRatesHasError = isNGas && !gasRate;

  if (selectedServiceList.length === 0 || !serviceAddress) {
    return false;
  }

  if (
    isElectricityRegistryDetailsLoading ||
    isGasRegistryDetailsLoading ||
    isElectricityRatesLoading ||
    isGasRateLoading
  ) {
    return false;
  }

  if (electricityRateshasError && !isNGas) {
    return false;
  }

  if (gasRatesHasError && !isElectricityInServiceList) {
    return false;
  }

  if (isRLPGOnly) {
    return false;
  }

  if (isLPGOnly) {
    return false;
  }

  return true;
};

export const useBillCompareOutputStateSetter = () => {
  const dispatch = useDispatch();
  return useCallback((payload) => dispatch(updateBillCompareOutput(payload)), [
    dispatch,
  ]);
};

export function useBillCompareSelector() {
  return useSelector(getBillCompareInfo);
}

export function getBillCompareInfo(state): BillCompareState {
  return state.BillCompare || initialState;
}

export const useEnrichedCards = () => {
  const state = useBillCompareSelector();
  const isLPGInServiceList = useIsLPGInServiceList();
  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();
  const gasIcp = usePropertyInfoGasICPSelector();
  const isGasInServiceList = useIsGasInServiceList();
  const { data: gasRate } = useGasRates(gasIcp);

  let idList: Array<planItems> = [];
  if (state && !state?.choosePlan && !isLPGInServiceList) {
    const totalBillAmount = state?.userInput?.billSplitInput?.bills.reduce(
      (previous, current) => previous + (current.totalAmount || 0),
      0
    );

    state.billEstimateResult?.result.map((result) => {
      if (
        result &&
        totalBillAmount &&
        result.billDetails?.summary?.grandTotalInclGst < totalBillAmount
      ) {
        const amtSaved =
          totalBillAmount - result.billDetails?.summary?.grandTotalInclGst;
        const results = { amt: amtSaved, planId: result?.plan };
        idList.push(results);
      }
    });
  }

  if (isGasInServiceList && (isSouthIsland || !gasRate)) {
    idList = [];
  }

  return idList;
};

export const useBillCompareState = (
  isCSR: boolean,
  journeyType: string
): JourneyState | undefined => {
  const Constants = useAgentConfig();
  const services = useServicesSelector();
  const [serviceAddress] = usePropertyInfoAddress();

  const icp = usePropertyInfoElectricityICPSelector();
  const gasIcp = usePropertyInfoGasICPSelector();

  const isElectricityInServiceList = useIsElectricityInServiceList();

  const { data: electricityRegistryDetails } = useElectricityICPDetails(icp);

  const { data: gasRegistryDetails } = useGasICPDetails(gasIcp);
  const { data: plansData } = usePlansData();
  const { data: sitecorePlans } = usePlanOffers(
    true,
    journeyType,
    Constants.planServiceDetails.basicPlan
  );

  const plans = useMemo(
    () =>
      plansData.filter(
        (plan) =>
          !!sitecorePlans.find(
            (offer) =>
              offer.fields.ExistingPlan.fields.ApiPlanId.value === plan.Id
          )
      ),
    [sitecorePlans, plansData]
  );

  const selectedPlan = usePlanInfoSelectedPlanSelector();
  const planId = selectedPlan?.Id;
  const hasNoIcp = !icp;
  const { planServiceDetails } = useAgentConfig();
  const ratesConstants = planServiceDetails.joinRates;
  const {
    data: electricityRates,
    isLoading: isElectricityRatesLoading,
  } = useElectricityRates(icp, planId, hasNoIcp, ratesConstants.rateLabels);

  const isSouthIsland = usePropertyInfoAddressIsSouthIsland();

  const broadbandAddressDetails = useBroadbandInfoAddressDetailsSelector();
  const { data: broadbandOffers } = useBroadbandOffers(isCSR, journeyType);
  const isFibreAvailable = useIsFibreAvailable(isCSR, journeyType);
  const addressDetails = usePropertyInfoAddressDetailsSelector();
  const { data: bcDisclaimer } = useSitecoreDisclaimer(isCSR, journeyType);
  const disclaimerText = bcDisclaimer?.fields.Msg.value;

  const { data: standardElectricityPrices } = useStandardElectricityPrices(
    icp,
    planId
  );

  const isElectricityNotQualified =
    standardElectricityPrices?.isCapacity ||
    standardElectricityPrices?.isDemand ||
    electricityRegistryDetails?.isDistance ||
    electricityRegistryDetails?.isUnmeteredLoad;

  const [elecUserType] = usePropertyInfoElectricityUserType();
  const elecUsageType =
    elecUserType === USER_TYPE_LOW ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD;

  const hasElectricityRatesError = useCallback(
    (rates) => {
      return Boolean(
        rates.find(
          (rate) =>
            rate.type === SERVICE_ID_ELECTRICITY &&
            ((elecUsageType === USAGE_TYPE_STANDARD &&
              !rate.standardRatesGSTExclusive.length) ||
              (elecUsageType === USAGE_TYPE_LOW &&
                !rate.lowRatesGSTExclusive.length))
        )
      );
    },
    [elecUsageType]
  );

  const isRatesError =
    (isElectricityInServiceList &&
      !isElectricityRatesLoading &&
      electricityRates?.length === 0) ||
    hasElectricityRatesError(electricityRates);

  const { data: billComparePromoCode } = useSiteCoreBCPromoCode(
    isCSR,
    journeyType
  );
  const addressHasTou = useBillingEligibilitySmartTimeOfUse();

  const isLowGasPricesAvailable = useIsLowGasPricesAvailable(gasIcp);

  const isBroadbandPromoBoxFeatureOn = true;

  const previousBillCompareState = useBillCompareSelector();

  const isBillCompareReloaded = previousBillCompareState?.userInput
    ? true
    : false;

  return !serviceAddress ||
    (isElectricityInServiceList &&
      (!electricityRegistryDetails ||
        isRatesError ||
        isElectricityNotQualified)) ||
    !broadbandAddressDetails ||
    !broadbandOffers ||
    isEmpty(services) ||
    isEmpty(plans)
    ? undefined
    : {
        ...previousBillCompareState?.userInput,
        userType: isCSR ? "AGENT" : "CUSTOMER",
        journeyType: journeyType.toUpperCase() as JourneyType,
        services,
        isSouthIsland,
        gasRegistryDetails,
        electricityRegistryDetails,
        broadbandAddressDetails,
        broadbandOffers,
        isFibreAvailable,
        serviceAddress,
        plans,
        sitecorePlans,
        billComparePromoCode,
        baseAxiosConfig,
        addressDetails,
        isBroadbandPromoBoxFeatureOn,
        isLowGasPricesAvailable,
        disclaimerText,
        isBillCompareReloaded,
        addressHasTou,
      };
};

export const mapUserType = (
  billCompareUserType: ServiceUserType | undefined
): "Standard" | "Low" | undefined => {
  if (billCompareUserType === "S") {
    return "Standard";
  }
  if (billCompareUserType === "L") {
    return "Low";
  }
  return undefined;
};
