/* eslint-disable @typescript-eslint/no-empty-interface */
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import {
  AccountsApiResponse,
  ConfigVersion,
  ConfigPath,
  getConfig,
} from '@contact/data-access';

export const useConfig = (
  version: ConfigVersion,
  application: string,
  profile: string,
  options?: UseQueryOptions<AccountsApiResponse>
) =>
  useQuery<any>(
    [ConfigPath[version]?.(application, profile)],
    async () => {
      const { data } = await getConfig(version, application, profile);
      return data;
    },
    {
      suspense: true,
      ...options,
    }
  );

interface CustomerJourneysConfigResponse {
  PipedGasRestrictionFlag: boolean;
  CustomerJourneysBillCompareFlag: boolean;
}
export const useCustomerJourneysConfig = (
  version: ConfigVersion,
  options?: UseQueryOptions<AccountsApiResponse>
): UseQueryResult<CustomerJourneysConfigResponse> =>
  useConfig(version, 'contact-react', 'customer-journeys', options);

interface CSRJourneysConfigResponse {
  PipedGasRestrictionFlag: boolean;
  CustomerJourneysBillCompareFlag: boolean;
}
export const useCSRJourneysConfig = (
  version: ConfigVersion,
  options?: UseQueryOptions<AccountsApiResponse>
): UseQueryResult<CSRJourneysConfigResponse> =>
  useConfig(version, 'contact-react', 'csr-journeys', options);

interface ChangePlanConfigResponse {}
export const useChangePlanConfig = (
  version: ConfigVersion,
  options?: UseQueryOptions<AccountsApiResponse>
): UseQueryResult<ChangePlanConfigResponse> =>
  useConfig(version, 'contact-react', 'change-plan', options);
